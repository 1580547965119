<template>
  <div class="dump-page animated fadeIn container">
    <h1>연락처 관리</h1>
    <b-input-group class="mt-4">
      <b-btn variant="primary" class="fl" @click.prevent="showAddPopup">추가</b-btn>
    </b-input-group>
    <table class="border-table mt-4" v-if="items.length > 0">
      <thead>
        <tr>
          <td width="200px">추가일</td>
          <td>그룹 이름</td>
          <td>개수</td>
          <td width="200px"></td>
        </tr>
      </thead>
      <tbody>
        <tr :key="item.id" v-for="item in items">
          <td>{{ item.updatedAt | dateShortFormat }}</td>
          <td>{{ item.groupName }}</td>
          <td>{{ item.count }}</td>
          <td class="text-center">
            <b-btn variant="primary" @click.prevent="getAttachment(item)">다운로드</b-btn>
            <b-btn variant="danger" class="ml-2" @click.prevent="deleteContact(item.id)">삭제</b-btn>
          </td>
        </tr>
      </tbody>
    </table>
    <b-modal ref="addModal" title="추천코드 회원 명단" centered hide-footer>
      <table class="border-table">
        <tr>
          <td width="160px">그룹명</td>
          <td>
            <b-form-input type="text" class="form-control" placeholder="이름" v-model="groupName" />
          </td>
        </tr>
        <tr>
          <td>파일로 업로드</td>
          <td>
            <input type="file" @change="changeAddFiles" />
            <div class="mt-2">
              <a href="/assets/sample.txt" style="color:#a0a0a0;text-decoration:underline;float:right" download=""
                >메모장 샘플</a
              >
              <a
                href="/assets/sample.xlsx"
                style="color:#a0a0a0;text-decoration:underline;float:right;margin-right:20px"
                download=""
                >엑셀 샘플</a
              >
            </div>
          </td>
        </tr>
      </table>

      <b-btn class="mt-3" ref="uploadBtn" variant="primary" block @click="create">추가</b-btn>
    </b-modal>
  </div>
</template>

<script>
import ContactService from '@/services/ContactService'

export default {
  name: 'Contact',
  components: {},
  data() {
    return {
      items: [],
      files: null,
      groupName: '',
      isLoading: false,
    }
  },
  mounted() {
    document.getElementsByClassName('app-body')[0].classList.add('bg-white')
    this.getList()
  },
  methods: {
    async getList() {
      const response = await ContactService.list()
      if (response.status == 200) {
        this.items = response.data.list
      }
    },
    async create() {
      try {
        this.isLoading = true

        var formData = new FormData()
        formData.append('groupName', this.groupName)

        var files = this.files
        if (files && files.length > 0) {
          formData.append('excel', files[0])
        }

        const response = await ContactService.create(formData)
        if (response.status !== 200) {
          return
        }
        this.getList()
        this.$refs['addModal'].hide()
      } catch (e) {
        // Do nothing
      } finally {
        this.isLoading = false
      }
    },
    async deleteContact(id) {
      try {
        this.isLoading = true
        const response = await ContactService.delete(id)
        if (response.status !== 200) {
          return
        }
        this.getList()
      } catch (e) {
        // do nothing
      } finally {
        this.isLoading = false
      }
    },
    async getAttachment(item) {
      this.isLoading = true
      const t = this
      ContactService.getAttachment(item.id)
        .then(response => {
          t.isLoading = false
          const url = window.URL.createObjectURL(new Blob(['\ufeff', response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', item.groupName + '.csv')
          document.body.appendChild(link)
          link.click()
        })
        .catch(() => {
          t.isLoading = false
        })
    },
    async showAddPopup() {
      this.files = null
      this.groupName = ''
      this.$refs['addModal'].show()
    },
    changeAddFiles(e) {
      this.files = e.target.files
    },
  },
  watch: {
    isLoading() {
      this.$refs.uploadBtn.disabled = this.isLoading
    },
    files() {},
  },
}
</script>
